import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

const CustomDateInput = React.forwardRef((props, ref) => {
  const { value, onClick, placeholder, onShowCalendar } = props

  const handleInputClick = useCallback(() => {
    onShowCalendar()
    onClick()
  }, [onClick, onShowCalendar])

  return (
    <input
      readOnly
      ref={ref}
      type="text"
      value={value}
      placeholder={placeholder}
      onClick={handleInputClick}
      className="w-full cursor-pointer focus:outline-none !text-deepspace"
      data-testid={props['data-testid']}
    />
  )
})

CustomDateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  onShowCalendar: PropTypes.func.isRequired,
  'data-testid': PropTypes.string.isRequired,
}

const Memoized = React.memo(CustomDateInput)
export default Memoized
