import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ChimeModal from '~/components/modals/Chime'
import {
  selectIsMobile,
} from '~/store/modules/dimensions/selectors'
import {
  NO_BANK_CLICKED,
} from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'

import AddDifferentAccount from './Plaid/AddDifferentAccount'
import LinkAccountV1 from './variants/V1'
import LinkAccountV2 from './variants/V2'
import LinkAccountV3 from './variants/V3'
import { LINK_ACCOUNT_VARIANTS } from './constants'

const LINK_ACCOUNT_COMPONENTS = {
  [LINK_ACCOUNT_VARIANTS.V1]: LinkAccountV1,
  [LINK_ACCOUNT_VARIANTS.V2]: LinkAccountV2,
  [LINK_ACCOUNT_VARIANTS.V3]: LinkAccountV3,
}

const BankAccountManagement = props => {
  const {
    variant,
    onSuccess,
    isZeroLimitUser,
    forceMobile,
    redirect,
    onClick,
    plaidItemUuid,
    disabled,
    differentBankAccount,
    onLoading,
    uiConfig
  } = props

  const isMobile = useSelector(selectIsMobile)
  const trackNoBankClicked = useSegmentEvents([NO_BANK_CLICKED])

  const [accounts, setAccounts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)
  const [isChimeModalOpen, setIsChimeModalOpen] = useState(false)

  const handleChimeModalOpen = useCallback(() => {
    setIsChimeModalOpen(true)
    trackNoBankClicked()
  }, [setIsChimeModalOpen, trackNoBankClicked])

  const handleChimeModalClose = useCallback(() => setIsChimeModalOpen(false), [setIsChimeModalOpen])

  const handleLoading = useCallback(() => {
    onLoading()
    setIsLoading(true)
  }, [onLoading, setIsLoading])

  const handleSuccess = useCallback(newAccounts => {
    setIsLoading(false)
    setAccounts(newAccounts)
    onSuccess()
    setHasSucceeded(true)
  }, [setIsLoading, onSuccess, setAccounts, setHasSucceeded])

  const LinkAccountComponent = LINK_ACCOUNT_COMPONENTS[variant]

  return (
    <>
      {isZeroLimitUser ? (
        <AddDifferentAccount
          accounts={accounts}
          isLoading={isLoading}
          hasSucceeded={hasSucceeded}
          onLoading={handleLoading}
          onSuccess={handleSuccess}
        />
      ) : (
        <>
          {LinkAccountComponent ? (
            <LinkAccountComponent
              accounts={accounts}
              onClick={onClick}
              redirect={redirect}
              isMobile={forceMobile || isMobile}
              isLoading={isLoading}
              plaidItemUuid={plaidItemUuid}
              onLoading={handleLoading}
              onSuccess={handleSuccess}
              onChimeModalOpen={handleChimeModalOpen}
              disabled={disabled}
              differentBankAccount={differentBankAccount}
              uiConfig={uiConfig}
            />
          ) : null}
        </>
      )}

      <ChimeModal
        show={isChimeModalOpen}
        onClose={handleChimeModalClose}
      />
    </>
  )
}
BankAccountManagement.propTypes = {
  variant: PropTypes.string,
  onSuccess: PropTypes.func,
  isZeroLimitUser: PropTypes.bool,
  forceMobile: PropTypes.bool,
  redirect: PropTypes.bool,
  onClick: PropTypes.func,
  plaidItemUuid: PropTypes.string,
  disabled: PropTypes.bool,
  differentBankAccount: PropTypes.bool,
  onLoading: PropTypes.func,
  uiConfig: PropTypes.shape({
    btnText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnClassName: PropTypes.string,
    className: PropTypes.string,
    loaderClassName: PropTypes.string,
    loaderSize: PropTypes.string,
    plaidLinkClassName: PropTypes.string,
    isWithIcon: PropTypes.bool,
    showIncompatibleBankMessage: PropTypes.bool,
  })
}
BankAccountManagement.defaultProps = {
  variant: LINK_ACCOUNT_VARIANTS.V1,
  onSuccess: () => {},
  isZeroLimitUser: false,
  forceMobile: false,
  redirect: true,
  onClick: () => {},
  plaidItemUuid: '',
  disabled: false,
  differentBankAccount: false,
  onLoading: () => {},
  uiConfig: {
    btnText: 'Connect bank',
    btnVariant: '',
    btnClassName: '',
    className: '',
    loaderClassName: '',
    loaderSize: '',
    plaidLinkClassName: '',
    isWithIcon: false,
    showIncompatibleBankMessage: false,
  }
}

export default BankAccountManagement
