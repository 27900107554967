import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { useFetchUserQuery } from '~/store/api'
import { dismissSnackbarMessage } from '~/store/modules/snackbar'
import { selectIsUserAuthorized } from '~/store/modules/auth/selectors'
import { LINK_ACCOUNT_VARIANTS } from '~/features/BankAccountManagement/constants'

import LinkBankAccount from '~/features/BankAccountManagement'

const RelinkBankAccount = props => {
  const {
    snackbarMessage,
  } = props

  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsUserAuthorized)

  const {
    data = {},
  } = useFetchUserQuery(undefined, { skip: !isAuth })
  const { user = {} } = data

  const onPlaidSuccess = useCallback(() => {
    dispatch(dismissSnackbarMessage(snackbarMessage.key))
  }, [snackbarMessage.key, dispatch])

  return (
    <div className="flex">
      Please
      {' '}
      <LinkBankAccount
        onSuccess={onPlaidSuccess}
        variant={LINK_ACCOUNT_VARIANTS.V3}
        differentBankAccount={user?.accounts?.[0]?.blocked}
        plaidItemUuid={user?.accounts?.[0]?.blocked ? '' : user?.accounts?.[0]?.uuid}
        uiConfig={{
          plaidLinkClassName: 'inline',
          btnClassName: 'text-deepspace-700 hover:text-deepspace-700 hover:underline no-underline !text-base !p-0 !pl-1',
          btnText: 'relink your bank account.'
        }}
      />
    </div>
  )
}
RelinkBankAccount.propTypes = {
  snackbarMessage: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string
    }))
  }).isRequired,
}

const Memoized = React.memo(RelinkBankAccount)
export default Memoized
