export const getFigureClassName = idx => {
  switch (idx) {
    case 0:
      return 'parallelogram-left'
    case 1:
      return 'parallelogram'
    case 2:
      return 'parallelogram-right'
    default:
      return ''
  }
}

export const getPositionClassName = idx => {
  switch (idx) {
    case 0:
      return 'justify-start'
    case 1:
      return 'justify-center'
    case 2:
      return 'justify-end'
    default:
      return ''
  }
}
