import React from 'react'
import NiceModal from '@ebay/nice-modal-react'

import { MODAL_HASH_BASED_IDS, MODAL_IDS } from '~/constants'
import { useModalManagement } from '~/hooks/useModalManagement'
import SocureDocumentsUploadModal from '~/features/DocumentsManagement/modals/SocureDocuments'
import UserAgreementModal from '~/features/UserAgreement/Modal'
import UnlinkBillModal from '~/components/modals/UnlinkBill'
import RepaymentSuccessModal from '~/features/Repayments/modals/Success'
import RepaymentErrorModal from '~/features/Repayments/modals/Error'
import RepaymentRelinkAccountModal from '~/features/Repayments/modals/RelinkAccount'
import PrimeGuaranteeModal from '~/features/PrimeGuarantee/CreditScore/Modal'
import PrimeGuaranteeReferFriendModal from '~/features/PrimeGuarantee/CreditScoreIncreased/Modal'
import ConfirmLinkPaymentCardModal from '~/pages/ConfirmLinkPayment/Card/Modal'
import SMSOptInModal from '~/pages/Dashboard/modals/SMSOptIn'
import CreditScoreIncreaseModal from '~/pages/Dashboard/modals/CreditScoreIncrease'
import AccountReopenSuccessModal from '~/pages/Dashboard/AccountStatusWidget/modals/AccountReopenSuccess'
import CardActivationSuccessModal from '~/pages/Dashboard/modals/CardActivationSuccess'
import HighnoteTermsAndConditionsModal from '~/pages/Dashboard/modals/HighnoteTermsAndConditions'
import LearnMoreModal from '~/pages/Dashboard/modals/LearnMore'
import OnboardingCreditScoreDisclaimerModal from '~/pages/OnboardingCreditScore/DisclaimerModal'
import PersonalInformationEmailModal from '~/pages/Settings/Cards/PersonalInformation/Email/Modal'
import LinkBankAccountEducationModal from '~/pages/LinkBankAccount/Modal'
import DeleteDocumentModal from '~/features/DocumentsManagement/modals/DeleteDocument'
import UpgradeMembershipModal from '~/components/modals/UpgradeMembership'
import TooltipModal from '~/components/ui/Tooltip/Modal'
import RewardRedeemSuccessModal from '~/features/Rewards/Points/RedeemSuccessModal'
import CloseGoalModal from '~/pages/Dashboard/GoalsCard/modals/CloseGoal'
import RenameGoalModal from '~/pages/Dashboard/GoalsCard/modals/RenameGoal'
import FirstBillLinkedModal from '~/pages/Dashboard/modals/FirstBillLinked'
import OnboardingFinishedModal from '~/pages/Dashboard/modals/OnboardingFinished'
import LaunchMobileAppModal from '~/pages/Dashboard/modals/LaunchMobileApp'
import NativeAppLaunchModal from '~/pages/Dashboard/modals/NativeAppLaunch'
import SwitchPaymentProviderModal from '~/pages/Dashboard/SwitchPaymentProvider/Prompt/Modal'
import DOBFieldModal from '~/pages/ConfirmIdentity/components/Fields/DOB/Modal'
import NewAchAgreementModal from '~/features/BankAccountManagement/Plaid/Link/NewAchAgreementModal'

// Register modals
NiceModal.register(MODAL_IDS.USER_AGREEMENT, UserAgreementModal)
NiceModal.register(MODAL_IDS.UNLINK_BILL, UnlinkBillModal)
NiceModal.register(MODAL_IDS.REPAYMENT_SUCCESS, RepaymentSuccessModal)
NiceModal.register(MODAL_IDS.REPAYMENT_ERROR, RepaymentErrorModal)
NiceModal.register(MODAL_IDS.REPAYMENT_RELINK_ACCOUNT, RepaymentRelinkAccountModal)
NiceModal.register(MODAL_IDS.CONFIRM_LINK_PAYMENT_CARD, ConfirmLinkPaymentCardModal)
NiceModal.register(MODAL_IDS.SMS_OPT_IN, SMSOptInModal)
NiceModal.register(MODAL_IDS.CREDIT_SCORE_INCREASE, CreditScoreIncreaseModal)
NiceModal.register(MODAL_IDS.ACCOUNT_REOPEN_SUCCESS, AccountReopenSuccessModal)
NiceModal.register(MODAL_HASH_BASED_IDS.CARD_ACTIVATION_SUCCESS, CardActivationSuccessModal)
NiceModal.register(MODAL_HASH_BASED_IDS.HIGHNOTE_TERMS_AND_CONDITIONS, HighnoteTermsAndConditionsModal)
NiceModal.register(MODAL_IDS.ONBOARDING_CREDIT_SCORE_DISCLAIMER, OnboardingCreditScoreDisclaimerModal)
NiceModal.register(MODAL_IDS.PERSONAL_INFORMATION_EMAIL, PersonalInformationEmailModal)
NiceModal.register(MODAL_IDS.PRIME_GUARANTEE_MODAL, PrimeGuaranteeModal)
NiceModal.register(MODAL_IDS.LEARN_MORE_MODAL, LearnMoreModal)
NiceModal.register(MODAL_IDS.LINK_BANK_ACCOUNT_EDUCATION, LinkBankAccountEducationModal)
NiceModal.register(MODAL_IDS.DELETE_DOCUMENT, DeleteDocumentModal)
NiceModal.register(MODAL_IDS.REFER_FRIEND, PrimeGuaranteeReferFriendModal)
NiceModal.register(MODAL_IDS.TOOLTIP_MODAL, TooltipModal)
NiceModal.register(MODAL_IDS.UPGRADE_MEMBERSHIP_MODAL, UpgradeMembershipModal)
NiceModal.register(MODAL_IDS.REWARD_POINTS_REDEEM_SUCCESS, RewardRedeemSuccessModal)
NiceModal.register(MODAL_IDS.CLOSE_GOAL, CloseGoalModal)
NiceModal.register(MODAL_IDS.RENAME_GOAL, RenameGoalModal)
NiceModal.register(MODAL_IDS.FIRST_BILL_LINKED, FirstBillLinkedModal)
NiceModal.register(MODAL_IDS.ONBOARDING_FINISHED, OnboardingFinishedModal)
NiceModal.register(MODAL_HASH_BASED_IDS.LAUNCH_MOBILE_APP, LaunchMobileAppModal)
NiceModal.register(MODAL_IDS.NATIVE_APP_LAUNCH, NativeAppLaunchModal)
NiceModal.register(MODAL_IDS.DOB_MODAL, DOBFieldModal)
NiceModal.register(MODAL_IDS.NEW_ACH_AGREEMENT_MODAL, NewAchAgreementModal)

const ModalManager = () => {
  useModalManagement()

  return (
    <>
      {/* add other hash route specific modals */}
      <SocureDocumentsUploadModal
        id={MODAL_HASH_BASED_IDS.SOCURE_DOCUMENTS_UPLOAD}
      />
      <SwitchPaymentProviderModal
        id={MODAL_HASH_BASED_IDS.ACTIVATE_HIGHNOTE_CARD}
      />
    </>
  )
}

const Memoized = React.memo(ModalManager)
export default Memoized
