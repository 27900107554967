import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  useMigrateBillPayCardMutation,
  useFetchUserQuery,
} from '~/store/api'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { HIGHNOTE_CARD_ACTIVATED } from '~/services/segment'
import { useExperiment } from '~/hooks/useExperiment'
import {
  clearAllPreviousRoutes,
} from '~/store/modules/ui'
import {
  HIGHNOTE_ACH_AGREEMENT_LINK,
  // HIGHNOTE_CREDIT_CARD_AGREEMENT_LINK,
  HIGHNOTE_ESIGN_AGREEMENT_LINK,
  EXPERIMENTS,
} from '~/constants'
import { ROUTES } from '~/pages/constants'

import Modal from '~/components/ui/Modal'
import FormCheckboxField from '~/components/form/CheckboxField'
import Loader from '~/components/ui/Loader'
import Button from '~/components/ui/Button'
import Link from '~/components/ui/Link'

const HighnoteTermsAndConditionsModal = () => {
  const modal = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    refetch: refetchUser,
  } = useFetchUserQuery()
  const [migrateBillPayCard, migrateBillPayCardApiResult] = useMigrateBillPayCardMutation()
  const trackHighnoteCardActivated = useSegmentEvents([HIGHNOTE_CARD_ACTIVATED])
  const {
    enabled: isAchAgreementsExpEnabled,
  } = useExperiment(EXPERIMENTS.ACH_AGREEMENTS, false)

  const {
    control,
    formState: {
      isValid,
      isSubmitting,
      isDirty
    },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      hasAccepted: false,
    },
  })

  const handleCloseModal = useCallback(() => {
    modal.remove()
    navigate(location.pathname)
  }, [modal, navigate, location.pathname])

  const handleCheckboxToggle = useCallback(value => (
    setValue('hasAccepted', value !== 'true', { shouldDirty: true })
  ), [setValue])

  const onSubmit = useCallback(async () => {
    const apiResult = await migrateBillPayCard()
    if (!apiResult.error) {
      trackHighnoteCardActivated()
      handleCloseModal()
      dispatch(clearAllPreviousRoutes())
      navigate(ROUTES.POLL_HIGHNOTE_CARD)
      refetchUser()
    }
  }, [
    migrateBillPayCard,
    trackHighnoteCardActivated,
    handleCloseModal,
    dispatch,
    navigate,
    refetchUser,
  ])

  return (
    <Modal
      show
      size="md"
      onClose={handleCloseModal}
      className="p-5 sm:mx-0"
    >
      { migrateBillPayCardApiResult.isLoading
        ? <Loader />
        : (
          <>
            <h2 className="font-semibold mb-2">
              Review and accept
            </h2>
            <p className="text-sm font-semibold mb-4 leading-6">
              Please review and agree to additional disclosures.
              {' '}
              <span className="font-normal">
                To activate your new secured credit card, carefully read and accept the following:
              </span>
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormCheckboxField
                control={control}
                name="hasAccepted"
                onChange={handleCheckboxToggle}
                className="mb-4 text-xs leading-6 font-medium"
                key="highnote-terms-and-conditions-checkbox"
                id="highnote-terms-and-conditions-checkbox"
                data-testid="stellar-highnote-terms-and-conditions-checkbox"
                label={(
                  <p>
                    I have read and agree to the
                    {' '}
                    {/* <Link ### temporarily disabled due to ENG-758
                      className="font-bold cursor-pointer !inline mr-1 !text-deepspace"
                      to={HIGHNOTE_CREDIT_CARD_AGREEMENT_LINK}
                      target="_blank"
                      rel="noreferrer"
                      size=""
                    >
                      StellarFi Cardholder Agreement,
                    </Link> */}
                    {isAchAgreementsExpEnabled ? (
                      <Link
                        className="font-bold cursor-pointer !inline mr-1"
                        to={HIGHNOTE_ACH_AGREEMENT_LINK}
                        target="_blank"
                        rel="noreferrer"
                        size=""
                      >
                        ACH Agreement,
                      </Link>
                    ) : null}
                    <Link
                      to={HIGHNOTE_ESIGN_AGREEMENT_LINK}
                      target="_blank"
                      rel="noreferrer"
                      size=""
                      className="font-bold cursor-pointer !inline mr-1"
                    >
                      E-Sign Agreement,
                    </Link>
                    <Link
                      className="font-bold cursor-pointer !inline mr-1"
                      href="https://stellarfi.com/legal/fcra-notice"
                      target="_blank"
                      rel="noreferrer"
                      size=""
                    >
                      and Fair Credit Reporting Act Disclosure.
                    </Link>
                  </p>
                )}
              />
              {migrateBillPayCardApiResult.error ? (
                <p className="text-red text-sm pb-2">
                  {migrateBillPayCardApiResult.error.data?.errors?.[0]?.message}
                </p>
              ) : null}
              <Button
                variant="contained"
                className="w-full mb-4"
                type="submit"
                disabled={!isValid || isSubmitting || !isDirty}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                className="w-full"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </form>
            <p className="italic font-normal text-10 my-4">
              StellarFi is a financial technology company, not a bank.
              This card is issued by Community Federal Savings Bank, pursuant to a license from Visa U.S.A. Inc.
              <p>
                By submitting your application, you acknowledge and consent that StellarFi
                is required by law to verify your identity in accordance with the provisions outlined in our
                {' '}
                <Link
                  className="font-bold cursor-pointer"
                  href="https://stellarfi.com/legal/stellar-user-agreement"
                  target="_blank"
                  rel="noreferrer"
                  size=""
                >
                  Patriot Act Disclosure.
                </Link>
              </p>
            </p>
          </>
        ) }
    </Modal>
  )
}

export default NiceModal.create(HighnoteTermsAndConditionsModal)
