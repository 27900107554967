/* eslint-disable max-len */
import CONFIG from '~/config'
import {
  getUserFirstName,
} from '~/utils'

export const ADD_BANK_CLICKED = 'ADD_BANK_CLICKED'
export const ADDED_TO_HOME_SCREEN = 'ADDED_TO_HOME_SCREEN'
export const AFFILIATE_LINK_CLICKED = 'AFFILIATE_LINK_CLICKED'
export const BANK_ACCOUNT_LINK_ERRORED = 'BANK_ACCOUNT_LINK_ERRORED'
export const BANK_ACCOUNT_LINKED = 'BANK_ACCOUNT_LINKED'
export const BILL_CREATED = 'BILL_CREATED'
export const BILL_LINKED = 'BILL_LINKED'
export const CONNECT_ACCOUNT_WITH_GOOGLE = 'CONNECT_ACCOUNT_WITH_GOOGLE'
export const CONNECT_ACCOUNT_WITH_APPLE = 'CONNECT_ACCOUNT_WITH_APPLE'
export const EXPERIMENT_ENTERED = 'EXPERIMENT_ENTERED'
export const IDENTITY_VERIFICATION_ATTEMPTED = 'IDENTITY_VERIFICATION_ATTEMPTED'
export const MEMBER_IDENTITY_ERRORED = 'MEMBER_IDENTITY_ERRORED'
export const MEMBER_IDENTITY_VERIFIED = 'MEMBER_IDENTITY_VERIFIED'
export const MEMBER_PHONE_NUMBER_VERIFIED = 'MEMBER_PHONE_NUMBER_VERIFIED'
export const MEMBER_PHONE_NUMBER_VERIFIED_ERROR = 'MEMBER_PHONE_NUMBER_VERIFIED_ERROR'
export const MEMBER_REGISTERED = 'MEMBER_REGISTERED'
export const MEMBER_SET_ADDRESS = 'MEMBER_SET_ADDRESS'
export const MEMBER_SET_DOB = 'MEMBER_SET_DOB'
export const MEMBER_SET_GOALS = 'MEMBER_SET_GOALS'
export const MEMBER_SET_PHONE_NUMBER = 'MEMBER_SET_PHONE_NUMBER'
export const MEMBER_SET_REFERRED_SOURCE = 'MEMBER_SET_REFERRED_SOURCE'
export const MEMBER_SUBSCRIBED = 'MEMBER_SUBSCRIBED'
export const NO_BANK_CLICKED = 'NO_BANK_CLICKED'
export const PROMO_CODE_REDEEMED = 'PROMO_CODE_REDEEMED'
export const SIGN_UP_GET_STARTED = 'SIGN_UP_GET_STARTED'
export const SKIPPED_PRODUCT_TOUR = 'SKIPPED_PRODUCT_TOUR'
export const USER_ACTIVATED = 'USER_ACTIVATED'
export const CONFIRM_ACCOUNT_ATTEMPTED = 'CONFIRM_ACCOUNT_ATTEMPTED'
export const USER_ACTIVATED_LOW_RISK = 'USER_ACTIVATED_LOW_RISK'
export const USER_AGREEMENT_ACCEPTED = 'USER_AGREEMENT_ACCEPTED'
export const SSN_VERIFIED = 'SSN_VERIFIED'
export const MARKETPLACE_OFFER_CLICKED = 'MARKETPLACE_OFFER_CLICKED'
export const CONNEXPAY_CARD_ACTIVATED = 'CONNEXPAY_CARD_ACTIVATED'
export const HIGHNOTE_CARD_ACTIVATED = 'HIGHNOTE_CARD_ACTIVATED'
export const PLAID_CONNECT_EVENT_CHANGED = 'PLAID_CONNECT_EVENT_CHANGED'
export const VIEW_STELLAR_CREDIT_REPORT = 'VIEW_STELLAR_CREDIT_REPORT'
export const BANK_BALANCE_EXPANDED = 'BANK_BALANCE_EXPANDED'
export const CURRENT_BALANCE_EDUCATION_CLICKED = 'CURRENT_BALANCE_EDUCATION_CLICKED'
export const AVAILABLE_BALANCE_EDUCATION_CLICKED = 'AVAILABLE_BALANCE_EDUCATION_CLICKED'
export const ALTERNATIVE_BANKING_PARTNER_CLICKED = 'ALTERNATIVE_BANKING_PARTNER_CLICKED'
export const FIRST_BILL_LINKED_NATIVE_APP_LAUNCH_MODAL_DISMISSED = 'FIRST_BILL_LINKED_NATIVE_APP_LAUNCH_MODAL_DISMISSED'

export const getSegmentUserContext = (user = {}) => {
  const {
    email,
  } = user

  return {
    traits: {
      email,
      firstName: getUserFirstName(user),
    }
  }
}

export const snippetForEnv = () => {
  // This comes from the Source tab in Segment
  // Important: remove the analytics.page() call from segment
  //    otherwise we'll double-record the first page
  const rawHTML = `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="Config.${CONFIG.SEGMENT_WRITE_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("${CONFIG.SEGMENT_WRITE_KEY}");
    }}();
  `
  return rawHTML
}

export const identifySegmentUser = user => {
  if (!user) return

  const traits = {
    email: user.email,
    firstName: getUserFirstName(user),
    registrationNextStep: user.registrationNextStep,
  }

  window.analytics.identify(user.uuid, traits)
}

export const logOutOfSegment = () => {
  window.analytics.reset()
}

export const trackPage = (properties = {}) => {
  window.analytics.page(properties)
}

export const trackEvent = (eventName, eventData, properties, options = {}) => {
  if (!options.isImpersonationMode) {
    window.analytics.track(eventName, eventData, properties)
  }
}

export const SEGMENT_EVENT_TRACK_FUNCTIONS_MAP = {
  [ADD_BANK_CLICKED]: (eventData, properties, options) => trackEvent('Add Bank Clicked', eventData, properties, options),
  [ADDED_TO_HOME_SCREEN]: (eventData, properties, options) => trackEvent('Added to Home Screen', eventData, properties, options),
  [AFFILIATE_LINK_CLICKED]: (eventData, properties, options) => trackEvent('Affiliate Link Clicked', eventData, properties, options),
  [BANK_ACCOUNT_LINK_ERRORED]: (eventData, properties, options) => trackEvent('Bank Account Link Errored', eventData, properties, options),
  [BANK_ACCOUNT_LINKED]: (eventData, properties, options) => trackEvent('Bank Account Linked', eventData, properties, options),
  [BILL_CREATED]: (eventData, properties, options) => trackEvent('Bill Created', eventData, properties, options),
  [BILL_LINKED]: (eventData, properties, options) => trackEvent('Bill Linked', eventData, properties, options),
  [CONNECT_ACCOUNT_WITH_GOOGLE]: (eventData, properties, options) => trackEvent('Connect Account with Google', eventData, properties, options),
  [CONNECT_ACCOUNT_WITH_APPLE]: (eventData, properties, options) => trackEvent('Connect Account with Apple', eventData, properties, options),
  [EXPERIMENT_ENTERED]: (eventData, properties, options) => trackEvent('Experiment Entered', eventData, properties, options),
  [IDENTITY_VERIFICATION_ATTEMPTED]: (eventData, properties, options) => trackEvent('Identity Verification Attempted', eventData, properties, options),
  [MEMBER_IDENTITY_ERRORED]: (eventData, properties, options) => trackEvent('Member Identity Errored', eventData, properties, options),
  [MEMBER_IDENTITY_VERIFIED]: (eventData, properties, options) => trackEvent('Member Identity Verified', eventData, properties, options),
  [MEMBER_PHONE_NUMBER_VERIFIED_ERROR]: (eventData, properties, options) => trackEvent('Member Phone Number Verified Error', eventData, properties, options),
  [MEMBER_PHONE_NUMBER_VERIFIED]: (eventData, properties, options) => trackEvent('Member Phone Number Verified', eventData, properties, options),
  [MEMBER_REGISTERED]: (eventData, properties, options) => trackEvent('Member Registered', eventData, properties, options),
  [MEMBER_SET_ADDRESS]: (eventData, properties, options) => trackEvent('Membership Set Address', eventData, properties, options),
  [MEMBER_SET_DOB]: (eventData, properties, options) => trackEvent('Membership Set DOB', eventData, properties, options),
  [MEMBER_SET_GOALS]: (eventData, properties, options) => trackEvent('Member Set Goals', eventData, properties, options),
  [MEMBER_SET_PHONE_NUMBER]: (eventData, properties, options) => trackEvent('Member Set Phone Number', eventData, properties, options),
  [MEMBER_SET_REFERRED_SOURCE]: (eventData, properties, options) => trackEvent('Member Set Referred Source', eventData, properties, options),
  [MEMBER_SUBSCRIBED]: (eventData, properties, options) => trackEvent('Membership Plan Selected', eventData, properties, options),
  [NO_BANK_CLICKED]: (eventData, properties, options) => trackEvent('No Bank Clicked', eventData, properties, options),
  [PROMO_CODE_REDEEMED]: (eventData, properties, options) => trackEvent('Promo Code Redeemed', eventData, properties, options),
  [SIGN_UP_GET_STARTED]: (eventData, properties, options) => trackEvent('Sign Up Get Started', eventData, properties, options),
  [SKIPPED_PRODUCT_TOUR]: (eventData, properties, options) => trackEvent('Skipped Product Tour', eventData, properties, options),
  [USER_ACTIVATED_LOW_RISK]: (eventData, properties, options) => trackEvent('User Activated - Low Risk', eventData, properties, options),
  [USER_ACTIVATED]: (eventData, properties, options) => trackEvent('User Activated', eventData, properties, options),
  [CONFIRM_ACCOUNT_ATTEMPTED]: (eventData, properties, options) => trackEvent('Confirm Account Attempted', eventData, properties, options),
  [USER_AGREEMENT_ACCEPTED]: (eventData, properties, options) => trackEvent('User Agreement Accepted', eventData, properties, options),
  [SSN_VERIFIED]: (eventData, properties, options) => trackEvent('SSN Verified', eventData, properties, options),
  [MARKETPLACE_OFFER_CLICKED]: (eventData, properties, options) => trackEvent('Marketplace Offer Clicked', eventData, properties, options),
  [CONNEXPAY_CARD_ACTIVATED]: (eventData, properties, options) => trackEvent('Connexpay Card Activated', eventData, properties, options),
  [HIGHNOTE_CARD_ACTIVATED]: (eventData, properties, options) => trackEvent('Highnote Card Activated', eventData, properties, options),
  [PLAID_CONNECT_EVENT_CHANGED]: (eventData, properties, options) => trackEvent('Plaid Connect Event Changed', eventData, properties, options),
  [VIEW_STELLAR_CREDIT_REPORT]: (eventData, properties, options) => trackEvent('View StellarFi Credit Report', eventData, properties, options),
  [BANK_BALANCE_EXPANDED]: (eventData, properties, options) => trackEvent('Bank Balance Expanded', eventData, properties, options),
  [CURRENT_BALANCE_EDUCATION_CLICKED]: (eventData, properties, options) => trackEvent('Current Balance Education Clicked', eventData, properties, options),
  [AVAILABLE_BALANCE_EDUCATION_CLICKED]: (eventData, properties, options) => trackEvent('Available Balance Education Clicked', eventData, properties, options),
  [ALTERNATIVE_BANKING_PARTNER_CLICKED]: (eventData, properties, options) => trackEvent('Alternative Banking Partner Clicked', eventData, properties, options),
  [FIRST_BILL_LINKED_NATIVE_APP_LAUNCH_MODAL_DISMISSED]: (eventData, properties, options) => trackEvent('First Bill Linked Native App Launch Modal Dismissed', eventData, properties, options),
}
