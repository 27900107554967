import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  useLazyFetchUserQuery,
} from '~/store/api'

import BankAccountLink from '~/features/BankAccountManagement/Link'
import Loader from '~/components/ui/Loader'
import Button from '~/components/ui/Button'

import { BANK_LINK_REASONS, LINK_ACCOUNT_VARIANTS } from '../constants'

const LinkAccountV3 = props => {
  const {
    accounts,
    isLoading,
    onLoading,
    onSuccess,
    onChimeModalOpen,
    onClick,
    plaidItemUuid,
    differentBankAccount,
    className,
    uiConfig,
  } = props

  const [fetchUser] = useLazyFetchUserQuery()

  const handleSuccess = useCallback(() => {
    fetchUser()
    onSuccess()
  }, [fetchUser, onSuccess])

  return (
    <>
      {isLoading ? (
        <Loader
          className={uiConfig?.loaderClassName}
          data-testid="stellar-link-bank-accounts-loader-v7"
          size={uiConfig.loaderSize}
        />
      ) : (
        <>
          <BankAccountLink
            accounts={accounts}
            onLoading={onLoading}
            onSuccess={handleSuccess}
            onClick={onClick}
            variant={LINK_ACCOUNT_VARIANTS.V3}
            plaidItemUuid={plaidItemUuid}
            reason={BANK_LINK_REASONS.ACCOUNT_SETUP}
            differentBankAccount={differentBankAccount}
            uiConfig={uiConfig}
          />
          {uiConfig.showIncompatibleBankMessage ? (
            <p className={`text-center mt-2 ${className}`}>
              <Button
                size=""
                className="mx-1 underline"
                onClick={onChimeModalOpen}
                data-testid="stellar-link-bank-account-button-v3"
              >
                Click here
              </Button>
              if you don&apos;t have a compatible bank account.
            </p>
          ) : null}
        </>
      )}
    </>
  )
}

LinkAccountV3.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  plaidItemUuid: PropTypes.string,
  differentBankAccount: PropTypes.bool,
  onChimeModalOpen: PropTypes.func.isRequired,
  uiConfig: PropTypes.shape({
    btnText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnClassName: PropTypes.string,
    className: PropTypes.string,
    loaderClassName: PropTypes.string,
    loaderSize: PropTypes.string,
    plaidLinkClassName: PropTypes.string,
    isWithIcon: PropTypes.bool,
    showIncompatibleBankMessage: PropTypes.bool,
  })
}
LinkAccountV3.defaultProps = {
  accounts: [],
  onClick: () => {},
  plaidItemUuid: '',
  differentBankAccount: false,
  uiConfig: {
    btnText: 'Connect bank',
    btnVariant: '',
    btnClassName: '',
    className: '',
    loaderClassName: '',
    loaderSize: '',
    plaidLinkClassName: '',
    isWithIcon: false,
    showIncompatibleBankMessage: false,
  }
}

const Memoized = React.memo(LinkAccountV3)
export default Memoized
