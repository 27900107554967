import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { EXPERIMENTS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'

import BankAccountLink from '~/features/BankAccountManagement/Link'
import Loader from '~/components/ui/Loader'
import { ROUTES } from '~/pages/constants'

import { BANK_LINK_REASONS, LINK_ACCOUNT_VARIANTS } from '../constants'

const LinkAccountV2 = props => {
  const {
    accounts,
    isLoading,
    onLoading,
    onSuccess,
    onClick,
    redirect,
    plaidItemUuid,
    differentBankAccount,
    uiConfig,
  } = props

  const navigate = useNavigate()

  const {
    enabled: isPreMembershipPlanEducationEnabled,
    isLoaded: isPreMembershipPlanEducationLoaded,
  } = useExperiment(EXPERIMENTS.PRE_MEMBERSHIP_PLAN_EDUCATION)

  const handleSuccess = useCallback(() => {
    onSuccess()
    if (redirect) {
      isPreMembershipPlanEducationEnabled
        ? navigate(ROUTES.CHOOSE_SUBSCRIPTION)
        : navigate(ROUTES.SUBSCRIPTION)
    }
  }, [onSuccess, navigate, redirect, isPreMembershipPlanEducationEnabled])

  return (
    <>
      {isLoading || !isPreMembershipPlanEducationLoaded ? (
        <Loader data-testid="stellar-link-bank-accounts-loader-v2" />
      ) : (
        <BankAccountLink
          accounts={accounts}
          onLoading={onLoading}
          onSuccess={handleSuccess}
          variant={LINK_ACCOUNT_VARIANTS.V2}
          plaidItemUuid={plaidItemUuid}
          reason={BANK_LINK_REASONS.ACCOUNT_SETUP}
          differentBankAccount={differentBankAccount}
          onClick={onClick}
          uiConfig={uiConfig}
        />
      )}
    </>
  )
}
LinkAccountV2.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  plaidItemUuid: PropTypes.string,
  redirect: PropTypes.bool,
  differentBankAccount: PropTypes.bool,
  onClick: PropTypes.func,
  uiConfig: PropTypes.shape({
    btnText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnClassName: PropTypes.string,
    plaidLinkClassName: PropTypes.string,
  })
}
LinkAccountV2.defaultProps = {
  accounts: [],
  redirect: true,
  plaidItemUuid: '',
  differentBankAccount: false,
  onClick: () => null,
  uiConfig: {
    btnText: '',
    btnVariant: '',
    btnClassName: '',
    plaidLinkClassName: '',
  }
}

const Memoized = React.memo(LinkAccountV2)
export default Memoized
