export const LINK_ACCOUNT_VARIANTS = {
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
}

export const PROVIDER_TYPES = {
  PLAID: 'plaid',
}

export const BANK_LINK_REASONS = {
  ACCOUNT_SETUP: 'account_setup',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  HIGH_RISK: 'high_risk'
}
