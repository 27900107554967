import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { formatCentsToDollars } from '~/utils'
import { MODAL_IDS, ANIMATIONS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'
import { useUpdateUserSettingsMutation } from '~/store/api'
import { ROUTES } from '~/pages/constants'

import Link from '~/components/ui/Link'
import Modal from '~/components/ui/Modal'
import Button from '~/components/ui/Button'

const OnboardingFinishedModal = props => {
  const { creditsAvailableCents } = props

  const [updateUserSettings, updateUserSettingsApiResult] = useUpdateUserSettingsMutation()

  const modal = useModal()
  const dispatch = useDispatch()

  const handleCloseModal = useCallback(async () => {
    const apiResult = await updateUserSettings({ showPointsEarnedModal: false })
    if (!apiResult.error) {
      modal.remove()
      dispatch(removeModalFromQueue({ id: MODAL_IDS.ONBOARDING_FINISHED }))
    }
  }, [modal, dispatch, updateUserSettings])

  const formatedCredits = formatCentsToDollars(creditsAvailableCents, { maximumFractionDigits: 0 })

  return (
    <Modal
      show
      size="md"
      onClose={handleCloseModal}
      className="p-6"
      data-testid="stellar-dashboard-modals-onboarding-finished"
      animation={ANIMATIONS.CONFETTI}
    >
      <div className="flex flex-col">
        <p className="text-xl font-semibold mb-4">
          Points Earned
        </p>
      </div>
      <p className="text-base mb-4">
        Congratulations! You&apos;ve earned
        {' '}
        {formatedCredits}
        {' '}
        points. Points can be converted towards your next bill or membership fee.
      </p>
      <Link
        to={ROUTES.REWARDS}
        variant="contained"
        className="w-full mb-2 mt-1"
        onClick={handleCloseModal}
        data-testid="stellar-dashboard-modals-onboarding-finished-link"
      >
        Rewards page
      </Link>
      <Button
        variant="outlined"
        className="flex-grow justify-center w-full"
        onClick={handleCloseModal}
        data-testid="stellar-dashboard-modals-onboarding-finished-button"
      >
        Maybe later
      </Button>
      {updateUserSettingsApiResult.error ? (
        <p className="text-red">
          {updateUserSettingsApiResult.error.data?.errors?.[0]?.message}
        </p>
      ) : null}
    </Modal>
  )
}

OnboardingFinishedModal.propTypes = {
  creditsAvailableCents: PropTypes.number,
}
OnboardingFinishedModal.defaultProps = {
  creditsAvailableCents: 0,
}

export default NiceModal.create(OnboardingFinishedModal)
