import React, { useCallback, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'

import MobileModal from '~/components/ui/Modal/Mobile'
import DatePickerNew from '~/components/ui/DatePickerNew'

const DOBFieldModal = props => {
  const { handleSelectDate, value, maxDateAllowed, isMobile } = props

  const [isSlowDownModalClose, setIsSlowDownModalClose] = useState(false)

  const modal = useModal()
  const dispatch = useDispatch()

  const handleDOBFieldV1ModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.DOB_MODAL }))
    setIsSlowDownModalClose(false)
  }, [modal, dispatch])

  const handleChange = useCallback(date => {
    handleSelectDate(date)
    setIsSlowDownModalClose(true)
  }, [handleSelectDate])

  return (
    <MobileModal
      show
      size="md"
      className="p-5"
      onClose={handleDOBFieldV1ModalClose}
      isSlowDownModalClose={isSlowDownModalClose}
    >
      <DatePickerNew
        showIcon
        isModal
        onChange={handleChange}
        scrollableYearDropdown
        yearDropdownItemNumber={200}
        maxDate={maxDateAllowed}
        value={value}
        showYearDropdown
        showMonthDropdown
        isDisplayedWithCalendarPopper={false}
        isMobile={isMobile}
        onIconClick={handleDOBFieldV1ModalClose}
        calendarClassName="!border-none"
      />
    </MobileModal>
  )
}

DOBFieldModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  maxDateAllowed: PropTypes.instanceOf(Date).isRequired,
  handleSelectDate: PropTypes.func.isRequired,
}

const Memoized = React.memo(DOBFieldModal)
export default NiceModal.create(Memoized)
