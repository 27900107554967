import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { REGISTRATION_STEPS,
  UPLOAD_DOCUMENTS_STEPS,
  STELLAR_CS_EMAIL,
  EXPERIMENTS } from '~/constants'
import {
  useFetchUserQuery,
} from '~/store/api'
import {
  clearAllPreviousRoutes,
} from '~/store/modules/ui'
import { useExperiment } from '~/hooks/useExperiment'

import CTALink from '~/components/ui/Links/CTALink'
import Loader from '~/components/ui/Loader'
import LinkBankAccount from '~/features/BankAccountManagement'
import { LINK_ACCOUNT_VARIANTS } from '~/features/BankAccountManagement/constants'

import { getNextStepRoute, getCompleteAccountSetupButtonLabel } from './utils'

const CompleteAccountSetupButton = props => {
  const {
    label,
    allSetBtn,
    linkAccountVariant,
    buttonClassName,
    redirect,
    onClick,
    variant,
    ...otherProps
  } = props

  const dispatch = useDispatch()

  const {
    enabled: isPreMembershipPlanEducationEnabled,
    isLoaded: isPreMembershipPlanEducationLoaded,
  } = useExperiment(EXPERIMENTS.PRE_MEMBERSHIP_PLAN_EDUCATION)

  const {
    data = {}
  } = useFetchUserQuery()

  const { user = {} } = data

  const nextStepRoute = useMemo(() => getNextStepRoute(user, isPreMembershipPlanEducationEnabled), [user, isPreMembershipPlanEducationEnabled])
  const defaultLabel = getCompleteAccountSetupButtonLabel(user)

  const handleButtonClick = useCallback(e => {
    onClick(e)
    dispatch(clearAllPreviousRoutes())
  }, [dispatch, onClick])

  const isUploadDocumentsStep = Boolean(UPLOAD_DOCUMENTS_STEPS[user.registrationNextStep])
  const isAllSet = user.registrationNextStep === REGISTRATION_STEPS.ALL_SET
  const isContactSupport = user.registrationNextStep === REGISTRATION_STEPS.CONTACT_SUPPORT
  const isLinkBankAccountStep = user.registrationNextStep === REGISTRATION_STEPS.LINK_BANK_ACCOUNT

  return isPreMembershipPlanEducationLoaded ? (
    <div data-testid="stellar-complete-setup-button">
      {isContactSupport ? (
        <CTALink
          href={STELLAR_CS_EMAIL}
          variant="contained"
          {...otherProps}
        >
          Contact support
        </CTALink>
      ) : null}

      {isAllSet && allSetBtn ? allSetBtn : null}

      {!isAllSet && !isContactSupport && !isUploadDocumentsStep ? (
        <>
          {isLinkBankAccountStep ? (
            <LinkBankAccount
              isZeroLimitUser={false}
              variant={linkAccountVariant}
              onClick={handleButtonClick}
              redirect={redirect}
              uiConfig={{
                btnText: label || defaultLabel,
                btnClassName: buttonClassName
              }}
            />
          ) : (
            <CTALink
              to={nextStepRoute}
              variant={variant}
              onClick={handleButtonClick}
              className={buttonClassName}
              {...otherProps}
            >
              {label || defaultLabel}
            </CTALink>
          )}
        </>
      ) : null}
    </div>
  ) : <Loader />
}
CompleteAccountSetupButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  allSetBtn: PropTypes.node,
  linkAccountVariant: PropTypes.string,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  redirect: PropTypes.bool,
}
CompleteAccountSetupButton.defaultProps = {
  variant: 'outlined',
  size: 'md',
  label: '',
  allSetBtn: null,
  linkAccountVariant: LINK_ACCOUNT_VARIANTS.V3,
  buttonClassName: '',
  onClick: () => {},
  redirect: false,
}

const Memoized = React.memo(CompleteAccountSetupButton)
export default Memoized
