import React from 'react'
import PropTypes from 'prop-types'

import { uppercaseFirstChar } from '~/utils'

import { getFigureClassName, getPositionClassName } from './utils'
import './index.scss'

const LinearProgressBarWithDescription = props => {
  const { progressSteps, activeProgressStep, progressBarColor, progressBarContent } = props

  return (
    <>
      <div className="flex my-6 justify-between flex-wrap">
        {progressSteps?.map((progressStep, idx) => {
          const isActiveStep = activeProgressStep === progressStep
          return (
            <div className={`flex w-1/3 text-xs ${getPositionClassName(idx)}`}>
              <span className={isActiveStep ? 'text-deepspace font-bold' : 'text-deepspace-300 font-normal'}>
                {uppercaseFirstChar(progressStep)}
              </span>
            </div>
          )
        })}
        {progressSteps?.map((progressStep, idx) => {
          const isActiveStep = activeProgressStep === progressStep
          return (
            <div className="flex w-1/3 text-xs">
              <div className={`h-2.5 w-full ${getFigureClassName(idx)} ${progressBarColor} ${isActiveStep ? 'opacity-100' : 'opacity-30'}`} />
            </div>
          )
        })}
      </div>
      <div className="mb-6 !text-sm">
        <p className="font-bold mb-1.5">
          {progressBarContent[activeProgressStep]?.title}
        </p>
        <p>{progressBarContent[activeProgressStep]?.description}</p>
      </div>
    </>
  )
}

LinearProgressBarWithDescription.propTypes = {
  progressSteps: PropTypes.number.isRequired,
  activeProgressStep: PropTypes.string.isRequired,
  progressBarColor: PropTypes.string.isRequired,
  progressBarContent: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired
}

const Memoized = React.memo(LinearProgressBarWithDescription)
export default Memoized
