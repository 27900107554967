/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useNonScrollablePage } from '~/hooks/useNonScrollablePage'
import Button from '~/components/ui/Button'
import StandardButton from '~/components/ui/Buttons/StandardButton'

import './styles.scss'

const MobileModal = props => {
  const {
    show,
    children,
    withCloseButton,
    onClose,
    className,
    'data-testid': testId,
    withButton,
    btnProps,
    timeout,
    ctaProps,
    isSlowDownModalClose,
  } = props

  const [isClosed, setIsClosed] = useState(false)

  useNonScrollablePage(show)

  const handleClose = useCallback(() => {
    setIsClosed(true)
    setTimeout(() => onClose(), timeout)
  }, [onClose, timeout])

  const handleClick = useCallback(event => {
    event.stopPropagation()
  }, [])

  const handleCta = useCallback(() => {
    ctaProps.onClick()
    handleClose()
  }, [ctaProps, handleClose])

  useEffect(() => {
    if (isSlowDownModalClose) {
      handleClose()
    }
  }, [isSlowDownModalClose, handleClose])

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      overlayClassName="fixed inset-0 bg-steel bg-opacity-70 flex justify-center items-center z-30"
      className={`fixed bottom-0 left-0 right-0 h-auto w-full  outline-0 [&>*:not(button)]:outline-0 
      ${ isClosed ? 'animate-modal-slide-down translate-y-full' : 'animate-modal-slide-up translate-y-0'}`}
      onRequestClose={handleClose}
    >
      <div
        data-dismiss="modal"
        className={classNames(
          'relative overflow-hidden bg-white rounded-t-3xl',
          className,
        )}
        data-testid={testId}
        onClick={handleClick}
      >
        {withCloseButton ? (
          <StandardButton
            size=""
            className="absolute top-4 right-4 p-2"
            onClick={handleClose}
            data-dismiss="modal"
            aria-label="Close"
          >
            <FontAwesomeIcon icon="fa-regular fa-xmark" className="text-2xl" />
          </StandardButton>
        ) : null}
        {children}
        {ctaProps ? (
          <Button variant={ctaProps.variant} className={ctaProps.className} onClick={handleCta}>
            {ctaProps.label}
          </Button>
        ) : null}
        {withButton ? (
          <Button
            data-dismiss="modal"
            aria-label="Close"
            variant="outlined"
            className={`w-full mb-2 ${btnProps.className}`}
            onClick={handleClose}
          >
            {btnProps.label || 'Ok, got it'}
          </Button>
        ) : null}
      </div>
    </ReactModal>
  )
}
MobileModal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  withCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  'data-testid': PropTypes.string,
  withButton: PropTypes.bool,
  btnProps: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string
  }),
  ctaProps: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func
  }),
  timeout: PropTypes.number,
  isSlowDownModalClose: PropTypes.bool,
}
MobileModal.defaultProps = {
  show: false,
  children: null,
  withCloseButton: false,
  onClose: () => {},
  'data-testid': '',
  withButton: false,
  btnProps: {
    label: '',
    className: ''
  },
  ctaProps: null,
  timeout: 500,
  isSlowDownModalClose: false,
}

export default MobileModal
