import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { MODAL_IDS, EXPERIMENTS } from '~/constants'
import { BANK_ACCOUNT_PROVIDERS } from '~/constants/bankAccounts'
import { useStellarPlaidLink } from '~/features/BankAccountManagement/Plaid/hooks/useStellarPlaidLink'
import { ADD_BANK_CLICKED } from '~/services/segment'
import { addModalToQueue } from '~/store/modules/ui'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { useExperiment } from '~/hooks/useExperiment'

import Button from '~/components/ui/Button'

import { LINK_ACCOUNT_VARIANTS } from '../../constants'

const PlaidLink = props => {
  const {
    variant,
    onClick,
    differentBankAccount,
    onLoading,
    onSuccess,
    onError,
    plaidItemUuid,
    uiConfig,
  } = props

  const {
    btnClassName,
    btnText,
    plaidLinkClassName,
    isWithIcon,
    btnVariant,
  } = uiConfig

  const dispatch = useDispatch()
  const trackAddBankClicked = useSegmentEvents([ADD_BANK_CLICKED])
  const {
    enabled: isAchAgreementsExpEnabled,
  } = useExperiment(EXPERIMENTS.ACH_AGREEMENTS, false)

  const {
    openPlaid,
    disabled,
  } = useStellarPlaidLink({
    onLoading,
    onSuccess,
    onError,
    plaidItemUuid
  })

  const handleOpenPlaid = useCallback(e => {
    onClick(e)
    openPlaid(e)
    trackAddBankClicked({ provider: BANK_ACCOUNT_PROVIDERS.PLAID })
  }, [openPlaid, trackAddBankClicked, onClick])

  // in case of re-linking we need to acquaint with the latest ACH agreement template
  const handleOpenAchAgreementModal = useCallback(() => {
    dispatch(addModalToQueue({ id: MODAL_IDS.NEW_ACH_AGREEMENT_MODAL, props: { openPlaid: handleOpenPlaid } }))
  }, [dispatch, handleOpenPlaid])

  const handleButtonClick = useCallback(e => {
    if (differentBankAccount && isAchAgreementsExpEnabled) {
      handleOpenAchAgreementModal()
    } else {
      handleOpenPlaid(e)
    }
  }, [
    handleOpenAchAgreementModal,
    handleOpenPlaid,
    differentBankAccount,
    isAchAgreementsExpEnabled,
  ])

  return (
    <div
      data-testid="stellar-link-bank-account-plaid-link"
      className={plaidLinkClassName}
    >
      {variant === LINK_ACCOUNT_VARIANTS.V1 ? (
        <Button
          variant="contained"
          size="sm"
          className={`w-full mb-2 ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v1"
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V2 ? (
        <Button
          variant={btnVariant}
          size="sm"
          className={`w-full mb-2 hover:bg-transparent ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v2"
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V3 ? (
        <Button
          variant={btnVariant}
          size="md"
          className={`bg-deepspace-50 w-full text-deepspace underline font-semibold text-lg p-3 hover:bg-transparent ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v3"
          btnText="Add checking account"
        >
          {isWithIcon ? (
            <FontAwesomeIcon
              icon="far fa-plus"
              className="w-4 h-4 text-black mr-2"
            />
          ) : null}
          {btnText || 'Connect bank'}
        </Button>
      ) : null}
    </div>
  )
}
PlaidLink.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func,
  differentBankAccount: PropTypes.bool,
  onLoading: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  plaidItemUuid: PropTypes.string,
  uiConfig: PropTypes.shape({
    btnText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnClassName: PropTypes.string,
    plaidLinkClassName: PropTypes.string,
    isWithIcon: PropTypes.bool,
  })
}
PlaidLink.defaultProps = {
  variant: LINK_ACCOUNT_VARIANTS.V1,
  onClick: () => {},
  differentBankAccount: false,
  onLoading: () => {},
  onError: () => {},
  plaidItemUuid: '',
  uiConfig: {
    btnText: 'Connect bank',
    btnVariant: '',
    btnClassName: '',
    plaidLinkClassName: '',
    isWithIcon: false,
  }
}

export default PlaidLink
