import React from 'react'
import PropTypes from 'prop-types'

import PlaidLink from '~/features/BankAccountManagement/Plaid/Link'

import { LINK_ACCOUNT_VARIANTS } from '../constants'

const BankAccountLink = props => {
  const {
    accounts,
    onLoading,
    onSuccess,
    variant,
    disabled,
    differentBankAccount,
    plaidItemUuid,
    onClick,
    uiConfig,
  } = props

  return (
    <PlaidLink
      accounts={accounts}
      onLoading={onLoading}
      onSuccess={onSuccess}
      variant={variant}
      disabled={disabled}
      differentBankAccount={differentBankAccount}
      plaidItemUuid={plaidItemUuid}
      onClick={onClick}
      uiConfig={uiConfig}
    />
  )
}

BankAccountLink.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  onLoading: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  differentBankAccount: PropTypes.bool,
  plaidItemUuid: PropTypes.string,
  onClick: PropTypes.func,
  uiConfig: PropTypes.shape({
    btnText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnClassName: PropTypes.string,
    plaidLinkClassName: PropTypes.string,
    isWithIcon: PropTypes.bool,
  })
}

BankAccountLink.defaultProps = {
  variant: LINK_ACCOUNT_VARIANTS.V1,
  disabled: false,
  onLoading: () => null,
  accounts: [],
  differentBankAccount: false,
  plaidItemUuid: '',
  onClick: () => {},
  uiConfig: {
    btnText: '',
    btnVariant: '',
    btnClassName: '',
    plaidLinkClassName: '',
    isWithIcon: false,
  }
}

export default BankAccountLink
