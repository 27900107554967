import React from 'react'
import PropTypes from 'prop-types'

import { PROGRESS_BARS as GENERAL_PROGRESS_BARS } from '~/constants'

import LinearOnboardingProgressBar from './Linear'
import LinearWithDotsOnboardingProgressBar from './LinearWithDots'
import LinearProgressBarWithDescription from './LinearWithDescription'

const PROGRESS_BARS = {
  [GENERAL_PROGRESS_BARS.LINEAR]: LinearOnboardingProgressBar,
  [GENERAL_PROGRESS_BARS.LINEAR_WITH_DOTS]: LinearWithDotsOnboardingProgressBar,
  [GENERAL_PROGRESS_BARS.LINEAR_WITH_DESCRIPTION]: LinearProgressBarWithDescription
}

const ProgressBar = props => {
  const {
    currentStep,
    progress,
    progressBar,
    progressSteps,
    activeProgressStep,
    progressBarColor,
    progressBarContent,
  } = props

  const ProgressBarComponent = PROGRESS_BARS[progressBar]

  return (
    <ProgressBarComponent
      currentStep={currentStep}
      progress={progress}
      progressSteps={progressSteps}
      activeProgressStep={activeProgressStep}
      progressBarColor={progressBarColor}
      progressBarContent={progressBarContent}
    />
  )
}
ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  progressBar: PropTypes.string,
  progressSteps: PropTypes.arrayOf(PropTypes.string),
  activeProgressStep: PropTypes.string,
  progressBarColor: PropTypes.string,
  progressBarContent: PropTypes.shape({}),
}

ProgressBar.defaultProps = {
  progressBar: GENERAL_PROGRESS_BARS.LINEAR,
  progressSteps: [],
  activeProgressStep: '',
  progressBarColor: '',
  progressBarContent: {},
}

const Memoized = React.memo(ProgressBar)
export default Memoized
