import { useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'

import {
  useGetLinkTokenQuery,
  useLinkBankAccountMutation,
  useRelinkBankAccountMutation
} from '~/store/api'
import {
  BANK_ACCOUNT_LINKED,
  BANK_ACCOUNT_LINK_ERRORED,
  PLAID_CONNECT_EVENT_CHANGED
} from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { BANK_ACCOUNT_PROVIDERS } from '~/constants/bankAccounts'

export const useStellarPlaidLink = props => {
  const {
    onLoading,
    onSuccess,
    onError,
    plaidItemUuid
  } = props

  const [linkBankAccount] = useLinkBankAccountMutation()
  const [relinkBankAccount] = useRelinkBankAccountMutation()

  const [
    trackBankAccountLinked,
    trackBankAccountLinkFailed,
    trackPlaidConnectEventChanged
  ] = useSegmentEvents([
    BANK_ACCOUNT_LINKED,
    BANK_ACCOUNT_LINK_ERRORED,
    PLAID_CONNECT_EVENT_CHANGED
  ])

  const linkTokenParams = plaidItemUuid ? { plaidItemUuid } : {}
  const { data: linkTokenResponse = {} } = useGetLinkTokenQuery(linkTokenParams)

  const linkAccount = useCallback(async (_, metadata) => {
    if (onLoading) onLoading()

    const linkData = {
      publicToken: metadata.public_token,
      institution: {
        name: metadata.institution.name,
        institutionId: metadata.institution.institution_id
      },
      account: {
        id: metadata.account?.id,
        name: metadata.account?.name,
        type: metadata.account?.type,
        subtype: metadata.account?.subtype,
        mask: metadata.account?.mask
      }
    }

    try {
      if (plaidItemUuid) {
        const response = await relinkBankAccount(plaidItemUuid)

        const eventData = {
          accountType: linkData.account.subtype,
          institutionName: linkData.institution.name,
          plaidItemId: response.data.user.accounts[0].uuid,
          provider: BANK_ACCOUNT_PROVIDERS.PLAID
        }
        trackBankAccountLinked(eventData)
      } else {
        const response = await linkBankAccount(linkData)

        const eventData = {
          accountType: linkData.account.subtype,
          institutionName: linkData.institution.name,
          plaidItemId: response.data.user.accounts[0].uuid,
          provider: BANK_ACCOUNT_PROVIDERS.PLAID
        }
        trackBankAccountLinked(eventData)
      }

      if (onSuccess) {
        const temp = metadata.accounts.map(a => ({
          name: a.name,
          mask: a.mask,
          subType: a.subtype
        }))

        onSuccess(temp)
      }
    } catch (err) {
      if (onError) onError({ apiError: err })
    }
  }, [linkBankAccount, trackBankAccountLinked, onSuccess, onLoading, relinkBankAccount, plaidItemUuid, onError])

  const onExit = useCallback((error, metadata) => {
    if (error) {
      const eventData = {
        errorCode: error.error_code,
        errorMessage: error.error_message,
        errorType: error.error_type,
        institutionName: metadata.institution?.name,
        institutionId: metadata.institution?.id,
        requestId: metadata.request_id,
        linkSessionId: metadata.linkSessionId,
        provider: BANK_ACCOUNT_PROVIDERS.PLAID
      }
      trackBankAccountLinkFailed(eventData)
      if (onError) onError({ plaidError: error })
    }
  }, [trackBankAccountLinkFailed, onError])

  const trackPlaidConnectEventChange = useCallback((eventName, metadata) => {
    trackPlaidConnectEventChanged({
      type: eventName,
      plaidItemUuid,
      ...metadata
    })
  }, [trackPlaidConnectEventChanged, plaidItemUuid])

  const { open, ready } = usePlaidLink({
    token: linkTokenResponse.linkToken,
    onSuccess: linkAccount,
    onEvent: trackPlaidConnectEventChange,
    onExit
  })

  return {
    openPlaid: open,
    disabled: !ready,
  }
}
