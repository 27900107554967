/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-datepicker/dist/react-datepicker.css'

import { checkIsAmericanTimezone } from '~/utils'

import Button from '~/components/ui/Button'

import CustomDateInput from './CustomDateInput'
import './styles.scss'

const DatePickerNew = props => {
  const {
    value,
    isModal,
    maxDate,
    showIcon,
    onChange,
    onClick,
    showYearDropdown,
    calendarClassName,
    showMonthDropdown,
    scrollableYearDropdown,
    yearDropdownItemNumber,
    isDisplayedWithCalendarPopper,
  } = props

  const isAmericanTimeZone = checkIsAmericanTimezone()

  const handleChange = useCallback(date => {
    onChange(date)
  }, [onChange])

  return (
    <DatePicker
      customInput={(
        <CustomDateInput
          onShowCalendar={onClick}
          placeholder={isAmericanTimeZone ? 'mm/dd/yyyy' : 'dd/mm/yyyy'}
          data-testid={props['data-testid']}
        />
      )}
      inline={isModal}
      selected={value || maxDate}
      value={value}
      onSelect={handleChange}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dateFormat={isAmericanTimeZone ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
      dateFormatCalendar={isAmericanTimeZone ? 'MMMM d, yyyy' : 'd MMMM yyyy'}
      yearDropdownItemNumber={yearDropdownItemNumber}
      scrollableYearDropdown={scrollableYearDropdown}
      maxDate={maxDate}
      placeholderText={isAmericanTimeZone ? 'mm/dd/yyyy' : 'dd/mm/yyyy'}
      calendarClassName={`w-full ${calendarClassName}`}
      showIcon={showIcon}
      dropdownMode="scroll"
      className="w-full focus-visible:outline-0 hover:bg-steel-50 rounded slide-down"
      popperClassName={isDisplayedWithCalendarPopper ? 'block' : 'hidden'}
      toggleCalendarOnIconClick={isDisplayedWithCalendarPopper}
      icon={(
        <Button onClick={onClick} variant="" className="!p-0" data-testid="stellar-datepicker-new">
          <FontAwesomeIcon
            icon="far fa-calendar"
            className="!h-[15px] !w-[15px] p-0 pt-2"
          />
        </Button>
      )}
    />
  )
}

DatePickerNew.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  showYearDropdown: PropTypes.bool,
  yearDropdownItemNumber: PropTypes.number,
  scrollableYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showIcon: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  isDisplayedWithCalendarPopper: PropTypes.bool,
  isModal: PropTypes.bool,
  onClick: PropTypes.func,
  calendarClassName: PropTypes.string,
  'data-testid': PropTypes.string,
}

DatePickerNew.defaultProps = {
  value: '',
  showMonthDropdown: false,
  showYearDropdown: false,
  yearDropdownItemNumber: 15,
  scrollableYearDropdown: true,
  showIcon: false,
  maxDate: null,
  isDisplayedWithCalendarPopper: true,
  isModal: false,
  calendarClassName: '',
  onClick: () => null,
  'data-testid': ''
}
const Memoized = React.memo(DatePickerNew)
export default Memoized
