import React, { useEffect } from 'react'
import lottie from 'lottie-web'
import { useSelector } from 'react-redux'

import confetti_animation from '~/lotties/confetti.json'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'

const ConfettiAnimation = () => {
  const isMobile = useSelector(selectIsMobile)

  useEffect(() => {
    const container = document.querySelector('#confetti-animation')

    lottie.loadAnimation({
      container,
      renderer: 'svg',
      animationData: confetti_animation,
      autoplay: true,
      loop: false,
    })
    container.style.transform = isMobile ? 'scale(1.8)' : 'scale(0.8)'

    return () => lottie.destroy()
  }, [isMobile])

  return (
    <div
      id="confetti-animation"
      className="h-screen fixed w-11/12 bottom-15 mx-auto left-0 right-0"
    />
  )
}

export default React.memo(ConfettiAnimation)
