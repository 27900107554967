import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CLOUDFLARE_IMAGE_IDS } from '~/constants'
import { getCloudflareImageURL } from '~/utils'

import Card from '~/components/ui/Card'
import Button from '~/components/ui/Button'
import Loader from '~/components/ui/Loader'
import BankAccountLink from '~/features/BankAccountManagement/Link'

import { BANK_LINK_REASONS } from '../constants'

const LinkAccountV1 = props => {
  const {
    accounts,
    isLoading,
    isMobile,
    differentBankAccount,
    onChimeModalOpen,
    onLoading,
    onSuccess,
    uiConfig,
  } = props

  return (
    <>
      <Card
        className="flex items-center gap-20 p-5 md:p-8"
        data-testid="stellar-link-bank-account-card-v1"
      >
        <div className={`${isMobile ? 'w-full' : 'w-1/2'}`}>
          {!accounts.length ? (
            <>
              <h1
                className="text-2xl md:text-4xl text-deepspace font-bold mb-4"
                data-testid="stellar-link-bank-accounts-no-accounts-title-v2"
              >
                Let&apos;s connect your bank account! We&apos;ll help you...
              </h1>
              <p className="mb-4">Complete your account setup by connecting your bank account to build credit through your bill payments.</p>
            </>
          ) : (
            <>
              <h1
                className="text-deepspace font-bold"
                data-testid="stellar-link-bank-accounts-title-v2"
              >
                Success!
              </h1>
              <p className="my-3">Your bank account has been added.</p>
            </>
          )}

          <div>
            {isLoading ? (
              <Loader data-testid="stellar-link-bank-accounts-loader-v2" />
            ) : (
              <div data-testid="stellar-link-bank-accounts-list-v2">
                {accounts.map(account => (
                  <div
                    className="flex flex-row items-center mb-2"
                    key={account.name}
                    data-testid={`stellar-link-bank-account-${account.name}-v2`}
                  >
                    <p className="text-sm">
                      {account.name}
                      {' '}
                      <span className="text-secondary">
                        &bull;&bull;&bull;&bull;&bull;
                        {account.mask}
                      </span>
                    </p>
                    <FontAwesomeIcon
                      icon="far fa-check"
                      className="ml-auto text-green"
                    />
                  </div>
                ))}
                <BankAccountLink
                  accounts={accounts}
                  onLoading={onLoading}
                  onSuccess={onSuccess}
                  btnClassName="mt-0 md:mt-5"
                  reason={BANK_LINK_REASONS.ACCOUNT_SETUP}
                  differentBankAccount={differentBankAccount}
                  uiConfig={uiConfig}
                />
              </div>
            )}
          </div>
          <p className="text-center">
            <Button
              size=""
              className="mx-1"
              onClick={onChimeModalOpen}
              data-testid="stellar-link-bank-account-button-v1"
            >
              Click here
            </Button>
            if you don&apos;t have a compatible bank account.
          </p>
        </div>
        {!isMobile ? (
          <div
            className="w-1/2"
            data-testid="stellar-link-bank-account-desktop-image-block-v2"
          >
            <img
              src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.GREEN_MAN_IN_SPACE)}
              alt="green man in space"
              className="w-80 m-auto"
            />
          </div>
        ) : null}
      </Card>
      <p className="text-center text-black mx-6 my-5">
        <FontAwesomeIcon
          icon="far fa-lock-keyhole"
          size="sm"
          className="mr-2"
        />
        StellarFi uses Plaid for bank-level security
      </p>
    </>
  )
}
LinkAccountV1.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  differentBankAccount: PropTypes.bool,
  onChimeModalOpen: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  uiConfig: PropTypes.shape({
    btnText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnClassName: PropTypes.string,
  })
}
LinkAccountV1.defaultProps = {
  differentBankAccount: false,
  uiConfig: {
    btnText: '',
    btnVariant: '',
    btnClassName: '',
  }
}

const Memoized = React.memo(LinkAccountV1)
export default Memoized
