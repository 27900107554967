import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { MODAL_IDS, HIGHNOTE_ACH_AGREEMENT_LINK } from '~/constants'
import Button from '~/components/ui/Button'
import Link from '~/components/ui/Link'
import Modal from '~/components/ui/Modal'
import { removeModalFromQueue } from '~/store/modules/ui'

const NewAchAgreementModal = props => {
  const {
    openPlaid,
  } = props

  const dispatch = useDispatch()
  const modal = useModal()

  const handleCloseModal = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.NEW_ACH_AGREEMENT_MODAL }))
  }, [modal, dispatch])

  const handleSubmitButtonClick = useCallback(() => {
    openPlaid()
    handleCloseModal()
  }, [openPlaid, handleCloseModal])

  return (
    <Modal
      show
      size="md"
      className="p-5"
      data-testid="stellar-new-ach-agreement-modal"
      onClose={handleCloseModal}
    >
      <h3 className="mb-3">
        Bank reautorization
      </h3>
      <p className="mb-3">
        Before you re-link your bank account, please review and agree to the updated
        <Link
          href={HIGHNOTE_ACH_AGREEMENT_LINK}
          target="_blank"
          rel="noreferrer"
          size=""
          className="mx-2 font-bold underline"
        >
          ACH Agreement
        </Link>
        to continue using StellarFi.
      </p>
      <Button
        variant="contained"
        className="w-full mb-3"
        onClick={handleSubmitButtonClick}
      >
        Agree and continue
      </Button>
      <Button
        variant="outlined"
        className="w-full"
        onClick={handleCloseModal}
      >
        Cancel
      </Button>
    </Modal>
  )
}
NewAchAgreementModal.propTypes = {
  openPlaid: PropTypes.func.isRequired,
}

export default NiceModal.create(NewAchAgreementModal)
